import React from "react";
function HeaderLower() {
  return (
    <div className="header_lower">
      <strong>BRINGING OUR PURPOSE TO LIFE</strong> <br />
      We strive to make the world a better place by acting on behalf of our
      community, our planet, and our associates.
    </div>
  );
}
export default HeaderLower;
